import React from "react";
import Layout from "../components/layout/layout";
import { container } from "../styles/guiaDeUso.module.scss";

const InformacionSeguridad = () => {
  return (
    <Layout>
      <div className={container}>
        <h3>INFORMACIÓN DE SEGURIDAD DEL COLLAR KOLYY</h3>
        <br />
        <h6>COMPATIBILIDAD ELECTROMAGNÉTICA</h6>
        <p>
          Deben evitarse siempre los campos magnéticos muy fuertes (p. ej.,
          transformadores), ya que pueden provocar variaciones en la
          transmisión.
          <br />
          <br /> El incumplimiento de estas instrucciones puede ocasionar fallas
          en el funcionamiento o daños a su dispositivo KOLYY.
        </p>
        <br />
        <h6>RIESGOS PARA LOS NIÑOS Y LAS PERSONAS QUE NECESITAN ATENCIÓN</h6>
        <p>
          El uso de este dispositivo no está destinado a personas (incluidos
          niños) con capacidades físicas, sensoriales o mentales limitadas o
          falta de experiencia y/o falta de conocimiento, a menos que estén
          supervisados por una persona responsable de su seguridad o hayan sido
          instruido sobre el uso del dispositivo. Nota: Algunos países, estados
          o regiones tienen leyes relacionadas con el seguimiento basado en la
          ubicación de personas, animales y objetos. Es responsabilidad
          exclusiva del propietario y usuario cumplir con la ley y las normas
          del área en la que se utiliza un collar KOLYY.
        </p>
        <br />
        <h6>CONSEJO DE SEGURIDAD</h6>
        <p>
          Importante: Aunque el collar KOLYY es de tamaño pequeño, puede ser
          demasiado grande o pesado para mascotas pequeñas o heridas. Si usted
          no está seguro de poder usar este dispositivo con su mascota, le
          recomendamos consultar a un médico de mascotas o veterinario.
        </p>
        <br />
        <h6>FUNCIONAMIENTO A BATERÍA</h6>
        <p>
          El collar KOLYY funciona con una batería recargable de polímero de
          iones de litio.
        </p>
        <ul>
          <li>
            La batería del collar KOLYY no se puede reemplazar y no se debe
            quitar.
          </li>
          <li>
            Las baterías no se pueden desmontar, arrojar al fuego ni
            cortocircuitar.
          </li>
          <li>
            No transporte ni almacene la batería junto con objetos metálicos que
            puedan provocar un cortocircuito en los terminales positivo y
            negativo del batería.
          </li>
          <li>
            Nunca exponga el collar KOLYY al calor excesivo, como la luz solar,
            el fuego o similares.
          </li>
          <li>
            No cargue la batería cerca de una fuente de calor o bajo la luz
            solar directa.
          </li>
          <li>Mantenga la batería alejada del agua.</li>
          <li>
            No introduzca objetos metálicos en la batería, no la golpee con un
            martillo ni la dañe o aplaste de otra forma.
          </li>
          <li>
            Si una batería tiene fugas, evite el contacto directo con el líquido
            y deseche la batería de manera segura.
          </li>
          <li>
            La batería del collar KOLYY no se puede reemplazar y no se debe
            quitar.
          </li>
          <li>
            Mantenga la batería fuera del alcance de los niños y las mascotas
            para evitar que se las traguen. La ingestión puede provocar
            quemaduras, perforación del tejido blando y muerte. Las quemaduras
            graves pueden ocurrir dentro de las 2 horas posteriores a la
            ingestión. En caso de ingestión de una celda o batería, busque
            atención médica o asistencia veterinaria con prontitud.
          </li>
          <li>
            Si la batería se sobrecalienta o se deforma durante la carga o el
            uso con el dispositivo, retírela inmediatamente del dispositivo o
            del cargador y no lo vuelvas a usar.
          </li>
          <li>
            En caso de que la batería muestre algún signo de desgaste (arañazos,
            mordeduras, etc.), deséchela y no la vuelva a utilizar.
          </li>
          <li>
            Utilice únicamente el cable USB que viene con su collar KOLYY. El
            uso de otros cables podría sobrecargar la batería y puede provocar
            un incendio o una explosión.
          </li>
          <li>
            Ignorar estas instrucciones puede causar daños e incluso hacer que
            la batería explote.
          </li>
        </ul>
        <br />
        <h6>DISPOSICIÓN</h6>
        <p>
          Las baterías son residuos peligrosos. Para el correcto desecho de las
          baterías, en las instalaciones de distribuidores de baterías
          comerciales, así como instalaciones de eliminación de residuos del
          ayuntamiento local. Si desea deshacerse de su collar KOLYY, debe
          seguir las disposiciones locales vigentes en ese momento. La
          información se puede obtener en las instalaciones de eliminación de
          residuos del ayuntamiento local.
        </p>
        <h6>DECLARACIÓN DE CONFORMIDAD</h6>
        <p>
          Texto breve de la Declaración de conformidad: Petki Labs SL declara
          por la presente que el collar Kolyy cumple con los requisitos
          fundamentales y otras disposiciones relevantes de la directiva ETSI EN
          301 489-1 Ver. 2.2.3 ETSI EN 301 489-17 Ver. 3.2.4 ETSI EN 301 489-19
          V2.1.1 ETSI EN 301 489-52 V1.2.1 ETSI EN 301 489-17 Ver. 3.2.4
        </p>
      </div>
    </Layout>
  );
};

export default InformacionSeguridad;
